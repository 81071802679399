import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "초기화면",
          breadcrumbs: ["초기화면"],
        },
      },
      {
        path: "/management-board",
        children: [
          {
            path: "board-category-manager",
            name: "board-category-manager",
            component: () =>
              import("@/views/management/board/BoardCategoryManager.vue"),
            meta: {
              pageTitle: "카테고리",
              breadcrumbs: ["게시판 관리", "카테고리"],
            },
          },
          {
            path: "board-manager",
            children: [
              {
                path: "",
                name: "board-manager",
                component: () =>
                  import("@/views/management/board/BoardManager.vue"),
                meta: {
                  pageTitle: "게시판",
                  breadcrumbs: ["게시판 관리", "게시판"],
                },
              },
              {
                path: "detail/:seq",
                name: "board-detail",
                component: () =>
                  import("@/views/management/board/BoardDetailView.vue"),
                meta: {
                  pageTitle: "상세보기",
                  breadcrumbs: ["게시판 관리", "게시판", "상세보기"],
                },
              },
              {
                path: "add",
                name: "board-add",
                component: () =>
                  import("@/views/management/board/BoardAddEditView.vue"),
                meta: {
                  pageTitle: "신규등록",
                  breadcrumbs: ["게시판 관리", "게시판", "신규등록"],
                },
              },
              {
                path: "edit/:seq",
                name: "board-edit",
                component: () =>
                  import("@/views/management/board/BoardAddEditView.vue"),
                meta: {
                  pageTitle: "수정",
                  breadcrumbs: ["게시판 관리", "게시판", "수정"],
                },
              },
            ],
          },
          {
            path: "news-manager",
            name: "news-manager",
            component: () => import("@/views/management/board/NewsManager.vue"),
            meta: {
              pageTitle: "언론기사",
              breadcrumbs: ["게시판 관리", "언론기사"],
            },
          },
        ],
      },
      {
        path: "/management-ip/ip-manager",
        name: "ip-manager",
        component: () => import("@/views/management/ip/IPManager.vue"),
        meta: {
          pageTitle: "승인목록",
          breadcrumbs: ["IP 관리", "IP 목록"],
        },
      },
      {
        path: "/management-ip/ip-certificate",
        name: "ip-certificate",
        component: () => import("@/views/management/ip/IPCertificate.vue"),
        meta: {
          pageTitle: "인증서 관리 목록",
          breadcrumbs: ["IP 관리", "인증서"],
        },
      },
      {
        path: "/management-ip/option-manager",
        children: [
          {
            path: "",
            name: "option-manager",
            component: () => import("@/views/management/ip/OptionManager.vue"),
            meta: {
              pageTitle: "IP속성",
              breadcrumbs: ["IP 관리", "IP속성"],
            },
          },
          {
            path: "detail/:seq",
            name: "option-detail",
            component: () =>
              import("@/views/management/ip/OptionDetailView.vue"),
            meta: {
              pageTitle: "IP속성",
              breadcrumbs: ["IP 관리", "IP속성", "상세보기"],
            },
          },
        ],
      },
      {
        path: "/management-ip/approval-processing",
        name: "approval-processing",
        component: () => import("@/views/management/ip/ApprovalProcessing.vue"),
        meta: {
          pageTitle: "미승인목록",
          breadcrumbs: ["IP 관리", "미승인목록"],
        },
      },
      {
        path: "/management-ip/requested-document",
        name: "requested-document",
        component: () => import("@/views/management/ip/RequestedDocument.vue"),
        meta: {
          pageTitle: "Requested IP Document",
          breadcrumbs: ["IP 관리", "Requested IP Document"],
        },
      },
      {
        path: "/management-ip/ip-category-manager",
        name: "ip-category-manager",
        component: () => import("@/views/management/ip/IPCategoryManager.vue"),
        meta: {
          pageTitle: "카테고리",
          breadcrumbs: ["IP 관리", "카테고리"],
        },
      },
      {
        path: "/management-ip/hot-ip",
        name: "hot-ip",
        component: () => import("@/views/management/ip/HotIP.vue"),
        meta: {
          pageTitle: "Hot IP",
          breadcrumbs: ["IP 관리", "Hot IP"],
        },
      },
      {
        path: "/management-ip/request",
        name: "request",
        component: () => import("@/views/management/ip/Request.vue"),
        meta: {
          pageTitle: "자료요청",
          breadcrumbs: ["IP 관리", "자료요청"],
        },
      },
      {
        path: "/management-ip/ip-request",
        children: [
          {
            path: "",
            name: "ip-request",
            component: () => import("@/views/management/ip/IPRequest.vue"),
            meta: {
              pageTitle: "IP Request",
              breadcrumbs: ["IP 관리", "IP Request"],
            },
          },
          {
            path: "detail/:seq",
            name: "ip-request-detail",
            component: () =>
              import("@/views/management/ip/IPRequestDetailView.vue"),
            meta: {
              pageTitle: "IP Request",
              breadcrumbs: ["IP 관리", "IP Request", "상세보기"],
            },
          },
        ],
      },
      {
        path: "/management-ip/wish-ip",
        name: "wish-ip",
        component: () => import("@/views/management/ip/WishIP.vue"),
        meta: {
          pageTitle: "관심 IP",
          breadcrumbs: ["IP 관리", "관심 IP"],
        },
      },
      {
        path: "/management-ip/briefing-paper",
        name: "briefing-paper",
        component: () => import("@/views/management/ip/BriefingPaper.vue"),
        meta: {
          pageTitle: "Briefing Paper",
          breadcrumbs: ["IP 관리", "Briefing Paper"],
        },
      },
      {
        path: "/management-user/member-manager",
        children: [
          {
            path: "",
            name: "member-manager",
            component: () =>
              import("@/views/management/user/MemberManager.vue"),
            meta: {
              pageTitle: "회원",
              breadcrumbs: ["회원 관리", "회원 리스트"],
            },
          },
          {
            path: "detail/:seq",
            name: "member-detail",
            component: () =>
              import("@/views/management/user/MemberDetailView.vue"),
            meta: {
              pageTitle: "회원",
              breadcrumbs: ["회원 관리", "회원 리스트", "상세보기"],
            },
          },
        ],
      },
      {
        path: "/management-user/verification-code",
        name: "verification-code",
        component: () => import("@/views/management/user/VerificationCode.vue"),
        meta: {
          pageTitle: "인증코드",
          breadcrumbs: ["회원 관리", "인증코드"],
        },
      },
      {
        path: "/management-user/member-request",
        name: "member-request",
        component: () => import("@/views/management/user/MemberRequest.vue"),
        meta: {
          pageTitle: "등급변경",
          breadcrumbs: ["회원 관리", "등급변경"],
        },
      },
      {
        path: "/management-user/longterm-non-member-manager",
        meta: {
          isLongterm: true,
        },
        children: [
          {
            path: "",
            name: "longterm-non-member-manager",
            component: () =>
              import("@/views/management/user/MemberManager.vue"),
            meta: {
              pageTitle: "장기미접속 회원",
              breadcrumbs: ["회원 관리", "장기미접속 회원 리스트"],
            },
          },
          {
            path: "detail/:seq",
            name: "longterm-non-member-detail",
            component: () =>
              import("@/views/management/user/MemberDetailView.vue"),
            meta: {
              pageTitle: "장기미접속 회원",
              breadcrumbs: ["회원 관리", "장기미접속 회원 리스트", "상세보기"],
            },
          },
        ],
      },
      // {
      // path: "/management-user/member-password",
      // name: "member-password",
      // component: () => import("@/views/management/user/MemberPassword.vue"),
      // meta: {
      //     pageTitle: "비밀번호 재발행",
      //     breadcrumbs: ["회원 관리", "비밀번호 재발행"],
      // },
      // },
      {
        path: "/management-group/group-manager",
        children: [
          {
            path: "",
            name: "group-manager",
            component: () => import("@/views/management/user/GroupManager.vue"),
            meta: {
              pageTitle: "조직",
              breadcrumbs: ["조직 관리", "조직 리스트"],
            },
          },
          {
            path: "detail/:seq",
            name: "group-detail",
            component: () =>
              import("@/views/management/user/GroupDetailView.vue"),
            meta: {
              pageTitle: "조직",
              breadcrumbs: ["조직 관리", "조직 리스트", "상세보기"],
            },
          },
        ],
      },
      {
        path: "/management-message/send-mail",
        name: "send-mail",
        component: () => import("@/views/management/message/SendMail.vue"),
        meta: {
          pageTitle: "Mail 발송",
          breadcrumbs: ["Message", "Mail 발송"],
        },
      },
      {
        path: "/management-message/sent-message",
        children: [
          {
            path: "/management-message/sent-message",
            name: "sent-message",
            component: () => import("@/views/management/message/Sent.vue"),
            meta: {
              pageTitle: "보낸 메세지",
              breadcrumbs: ["Message", "보낸 메세지"],
            },
          },
          {
            path: "detail/:seq",
            name: "sent-message-detail",
            component: () => import("@/views/management/message/Detail.vue"),
            meta: {
              pageTitle: "보낸 메세지",
              breadcrumbs: ["Message", "보낸 메세지", "상세보기"],
            },
          },
        ],
      },
      {
        path: "/management-support-program",
        children: [
          {
            path: "",
            name: "support-program",
            component: () =>
              import("@/views/management/supportprogram/SupportProgram.vue"),
            meta: {
              pageTitle: "Support Program",
              breadcrumbs: ["Support Program"],
            },
          },
          {
            path: "detail/:seq",
            name: "support-program-detail",
            component: () =>
              import(
                "@/views/management/supportprogram/SupportProgramDetailView.vue"
              ),
            meta: {
              pageTitle: "Support Program",
              breadcrumbs: ["Support Program", "상세보기"],
            },
          },
          {
            path: "add",
            name: "support-program-add",
            component: () =>
              import(
                "@/views/management/supportprogram/SupportProgramAddView.vue"
              ),
            meta: {
              pageTitle: "Support Program",
              breadcrumbs: ["Support Program", "추가"],
            },
          },
        ],
      },
      {
        path: "/management-support-program-link",
        children: [
          {
            path: "",
            name: "support-program-link",
            component: () =>
              import(
                "@/views/management/supportprogram/SupportProgramLink.vue"
              ),
            meta: {
              pageTitle: "Support Program Link",
              breadcrumbs: ["Support Program Link"],
            },
          },
          {
            path: "detail/:seq",
            name: "support-program-link-detail",
            component: () =>
              import(
                "@/views/management/supportprogram/SupportProgramLinkDetailView.vue"
              ),
            meta: {
              pageTitle: "Support Program Link",
              breadcrumbs: ["Support Program Link", "상세보기"],
            },
          },
          {
            path: "add",
            name: "support-program-link-add",
            component: () =>
              import(
                "@/views/management/supportprogram/SupportProgramLinkAddView.vue"
              ),
            meta: {
              pageTitle: "Support Program Link",
              breadcrumbs: ["Support Program Link", "추가"],
            },
          },
        ],
      },
      {
        path: "/management-system/system-category-manager",
        name: "system-category-manager",
        component: () =>
          import("@/views/management/system/SystemCategoryManager.vue"),
        meta: {
          pageTitle: "시스템 카테고리",
          breadcrumbs: ["시스템 관리", "시스템 카테고리"],
        },
      },
      {
        path: "/management-system/calendar",
        name: "system-calendar",
        component: () =>
          import("@/views/management/system/CalendarManager.vue"),
        meta: {
          pageTitle: "일정",
          breadcrumbs: ["시스템 관리", "일정"],
        },
      },
      {
        path: "/management-system/statistics",
        name: "system-statistics",
        component: () => import("@/views/management/system/Statistics.vue"),
        meta: {
          pageTitle: "통계",
          breadcrumbs: ["시스템 관리", "통계"],
        },
      },
      {
        path: "/management-system/banner",
        name: "system-banner",
        component: () => import("@/views/management/system/BannerManager.vue"),
        meta: {
          pageTitle: "배너",
          breadcrumbs: ["시스템 관리", "배너"],
        },
      },
      {
        path: "/management-system/system-attachments",
        name: "system-attachments",
        component: () => import("@/views/management/system/AttachManager.vue"),
        meta: {
          pageTitle: "첨부파일",
          breadcrumbs: ["시스템 관리", "첨부파일"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach(() => {
//   // reset config to initial state
//   store.commit(Mutations.RESET_LAYOUT_CONFIG);

//   store.dispatch(Actions.VERIFY_AUTH, {
//     api_token: JwtService.getToken(),
//   });

//   if (store.getters.isUserAuthenticated && !store.getters.isDateAuthenticated) {
//     store.dispatch(Actions.LOGOUT).then(() =>
//       router.push({
//         name: "sign-in",
//       })
//     );
//   }

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // 인증 확인
  const isUserAuthenticated = store.getters.isUserAuthenticated;

  if (!isUserAuthenticated) {
    if (to.name === "sign-in") {
      next();
    }
    store.dispatch(Actions.VERIFY_AUTH).then(() => {
      checkRequiresAuth(to, from, next);
    });
  } else {
    checkRequiresAuth(to, from, next);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

const checkRequiresAuth = (to, from, next) => {
  const isUserAuthenticated = store.getters.isUserAuthenticated;
  if (isUserAuthenticated) {
    next();
  } else {
    store.dispatch(Actions.VERIFY_AUTH);

    if (to.name != "sign-in") {
      next({ name: "sign-in" });
    }
  }
};

export default router;
